import revive_payload_client_8JKF1o04DG from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eU7HDAWqfG from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y7PXw7h34P from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Xodx30Lkl2 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.4.0_vue@3.4.37/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_x8UI18Pckv from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ed6it4neLw from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_caJkcDTVY8 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_86xPch8qt8 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oAzbY0v5pt from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.3_typescript@5.5.4_vue@3.4.37/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/uroweb/uroweb-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_6L4Tdz2NgO from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_criFr2u97m from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_dxJUnn2NFe from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_nuxt@3.12.4_vue@3.4.37/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_Te8lfITPyP from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_mMh7yWONho from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.6/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import gtm_cXsUZobmSF from "/builds/uroweb/uroweb-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_8JKF1o04DG,
  unhead_eU7HDAWqfG,
  router_Y7PXw7h34P,
  _0_siteConfig_Xodx30Lkl2,
  payload_client_x8UI18Pckv,
  navigation_repaint_client_ed6it4neLw,
  check_outdated_build_client_caJkcDTVY8,
  chunk_reload_client_86xPch8qt8,
  plugin_vue3_oAzbY0v5pt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6L4Tdz2NgO,
  plugin_criFr2u97m,
  plugin_dxJUnn2NFe,
  plugin_Te8lfITPyP,
  nuxt_plugin_mMh7yWONho,
  gtm_cXsUZobmSF
]