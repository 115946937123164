<template>
    <section
        class="section"
        :data-aside="$slots.aside ? sidebarPosition : null"
        :data-header="!!$slots.title || !!$slots.action"
    >
        <div class="section__wrapper">
            <header
                v-if="$slots.title || $slots.action"
                class="section__header"
            >
                <span
                    v-if="$slots.title"
                    class="section__title"
                >
                    <slot name="title" />

                    <span
                        v-if="$slots.action"
                        class="section__action"
                    >
                        <slot name="action" />
                    </span>
                </span>

                <span
                    v-if="$slots.subtitle"
                    class="section__subtitle"
                >
                    <slot name="subtitle" />
                </span>
            </header>

            <div class="section__content">
                <slot />

                <div
                    v-if="$slots.footer"
                    class="section__footer"
                >
                    <slot name="footer" />
                </div>
            </div>

            <aside
                v-if="$slots.aside"
                class="section__aside"
            >
                <slot name="aside" />
            </aside>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        sidebarPosition: {
            type: String,
            default: 'right',
            required: false
        }
    }
};
</script>

<style lang="less" src="./BaseSection.less" />
