import validate from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/builds/uroweb/uroweb-frontend/middleware/redirect.global.ts";
import manifest_45route_45rule from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}